import React, {useCallback, useEffect, useMemo} from 'react';
import {Button, Counter, Layout, TooltipWrap} from "@clout-team/web-components";
import Mic from "@clout-team/icons/react/Mic";
import MicOff from "@clout-team/icons/react/MicOff";
import ShareScreen from "@clout-team/icons/react/ShareScreen";
import Video from "@clout-team/icons/react/Video";
import VideoOff from "@clout-team/icons/react/VideoOff";
import PersonAdd from "@clout-team/icons/react/PersonAdd";
import StopSharing from "@clout-team/icons/react/StopSharing";
import Chat from "@clout-team/icons/react/Chat";
import {useDispatch, useSelector} from "react-redux";
import './ActionBar.scss';
import classNames from "classnames";
import {
  conferencePermissions,
  conferenceSelector,
  conferenceTitleSelector
} from "../../../../redux/selectors/conference";
import {
  userCurrentSelector, usersInMeetingSelector,
} from "../../../../redux/selectors/users";
import {setConfirmType, setRightBarType, setShowInviteUsers} from "../../../../redux/slices/ui";
import {
  uiSelectorCommentsCounters,
  uiSelectorIsAnswer,
  uiSelectorIsFull,
  uiSelectorIsHide, uiSelectorIsPinned, uiSelectorOs,
  uiSelectorRightBar,
  uiSelectorSize
} from "../../../../redux/selectors/ui";
import {UI_TYPE} from "../../../../constants/meeting";
import Hotkey from "../../../../components/Hotkey/Hotkey";
import CallEndButton from "../CallEndButton/CallEndButton";
import {toggleMuteAsync, toggleVideoAsync} from "../../../../redux/slices/users";
import {BRAND_NAME, DIALOG_CONFIRM, FRAME_RATE, RESOLUTION, RIGHT_BAR_TYPES} from "../../../../constants/contants";
import { settingsSelectorChatSettings, settingsSelectorConferenceSettings } from '../../../../redux/selectors/settings';
import {IPermissions} from "../../../../redux/slices/conferense";
import {isMobile} from "@clout-team/helpers";
import {ShareSingleton} from "../../../../janus/shareSingleton";
import {getAllCountResultText} from "../../../../helpers/functions";

const ActionBar = () => {
  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);


  const users = useSelector(usersInMeetingSelector),
    conferenceSettings = useSelector(settingsSelectorConferenceSettings),
    myUser = useSelector(userCurrentSelector),
    isHide = useSelector(uiSelectorIsHide),
    size = useSelector(uiSelectorSize),
    isPinnedUI = useSelector(uiSelectorIsPinned),
    rightBarType = useSelector(uiSelectorRightBar),
    fullScreen = useSelector(uiSelectorIsFull),
    sm = ['S', 'XS'].includes(size),
    answer = useSelector(uiSelectorIsAnswer),
    os = useSelector(uiSelectorOs),
    commentsCounters = useSelector(uiSelectorCommentsCounters),
    chatSettings = useSelector(settingsSelectorChatSettings),
    permissions: IPermissions = useSelector(conferencePermissions),
    confTitle = useSelector(conferenceTitleSelector),
    dispatch = useDispatch();
    const isShareScreenPermitted = conferenceSettings.everyoneCanShare;

  useEffect(() => {
    if (users.length > 0 && confTitle) {
      document.title = `${confTitle} • ${getAllCountResultText(L('conference.label.number_of_participants'), users.length)?.replace('{number}', users.length)} | ${BRAND_NAME} ${L('email.label.conference')?.toLowerCase()}`;
    }
  },[users.length, confTitle, L])

/*  useEffect(() => {
    const keydownHandle = (e: any) => {
      // TODO пересекаются с электроном
      // if ((e.ctrlKey || e.code === "MetaLeft") && e.code === 'KeyV') {
      //   toggleVideo();
      // }
      // if ((e.ctrlKey || e.code === "MetaLeft") && e.code === 'KeyM') {
      //   toggleAudio();
      // }
      // if ((e.ctrlKey || e.code === "MetaLeft") && e.code === 'KeyD') {
      //   toggleShare();
      // }
      // if ((e.ctrlKey || e.code === "MetaLeft") && e.code === 'KeyL') {
      //   onClickEnd();
      // }
    }

    document.addEventListener('keydown', keydownHandle)
    return () => {
      document.removeEventListener('keydown', keydownHandle)
    }
  }, [])*/

  const createHint = useCallback((hint: string, hotkeys: string[] = []) => {
    if (hotkeys.length > 0) {
      const index = hotkeys.findIndex(v => v === '⌘');
      if (index > -1 && os === UI_TYPE.WIN) {
        hotkeys[index] = 'Ctrl'
      }
    }
    return <Hotkey title={hint} icons={hotkeys}/>
  }, [os])

  const _openRightPanel = useCallback((): void => {
    const result = rightBarType === RIGHT_BAR_TYPES.PARTICIPANTS ? '' : RIGHT_BAR_TYPES.PARTICIPANTS;
    dispatch(setRightBarType(result));
  }, [dispatch, rightBarType])

  const toggleCommentsPanel = useCallback(() => {
    const result = rightBarType === RIGHT_BAR_TYPES.COMMENTS ? '' : RIGHT_BAR_TYPES.COMMENTS;
    dispatch(setRightBarType(result));
  }, [dispatch, rightBarType]);

  const toggleAudio = useCallback(() => {
    if (!permissions.microphone) {
      dispatch(setConfirmType({ type: DIALOG_CONFIRM.SET_PERMISSIONS }));
      return;
    }
    dispatch(toggleMuteAsync(myUser))
  }, [dispatch, myUser, permissions.microphone]);

  const toggleVideo = useCallback(() => {
    if (!permissions.camera) {
      dispatch(setConfirmType({ type: DIALOG_CONFIRM.SET_PERMISSIONS }));
      return;
    }
    dispatch(toggleVideoAsync(myUser))
  }, [dispatch, myUser, permissions.camera]);

  const toggleShare = useCallback(async () => {
    if (!myUser.isShare) {
      // // так как Сафари может только по требованию пользователя, запрашиваем здесь
      // // @ts-ignore
      // if (!navigator.mediaDevices.getDisplayMedia && navigator.getDisplayMedia) {
      //   // @ts-ignore
      //   navigator.mediaDevices.getDisplayMedia = navigator.getDisplayMedia.bind(navigator);
      // }
      // try {
      //   const stream = await navigator.mediaDevices.getDisplayMedia({
      //     audio: false,
      //     video: {
      //       frameRate: FRAME_RATE,
      //       width: RESOLUTION.WIDTH,
      //       height: RESOLUTION.HEIGHT,
      //       aspectRatio: { ideal: 1.7777777778 }
      //     }
      //   });
      //   startShareScreen(stream);
      //   // if (isElectron()) window?.ipcRenderer.send('electron-event', {type: 'request-start-share'});
      // } catch (err) {
      //   console.error(`Screen share error: ${err}`);
      // }
      ShareSingleton.startShareScreen()
    } else {
      ShareSingleton.stopShareScreen();
      // if (isElectron()) window?.ipcRenderer.send('electron-event', {type: 'request-stop-share'});
    }
  }, [myUser?.isShare]);

  const buttonAudio = useMemo(() => {
    if (!myUser) return null;
    return sm ?
      <TooltipWrap
        size={'big'}
        hint={myUser.audio ? createHint(L('calls.audio.mute_my_microphone'), ['⌘', 'M']) : createHint(L('calls.audio.unmute_my_microphone'), ['⌘', 'M'])}
      >
        {!permissions.microphone && <span className="media-permission">!</span>}
        <Button
          className={myUser.audio ? 'rounded-corners-8 custom-btn-style bg-state' : 'rounded-corners-8 custom-btn-style bg-state red-icon'}
          isStroke
          size={'lg'}
          iconInside={myUser.audio ? <Mic/> : <MicOff/>}
          color={'white'}
          onClick={toggleAudio}
        />
      </TooltipWrap> :
      <TooltipWrap
        size={'big'}
        hint={myUser.audio ? createHint(L('calls.audio.mute_my_microphone'), ['⌘', 'M']) : createHint(L('calls.audio.unmute_my_microphone'), ['⌘', 'M'])}
      >
        {!permissions.microphone && <span className="media-permission">!</span>}
        <Button
          className={myUser.audio ? 'rounded-corners-8 custom-btn-style bg-state' : 'rounded-corners-8 custom-btn-style bg-state red-icon'}
          iconLeft={myUser.audio ? <Mic width={24} height={24}/> : <MicOff width={24} height={24}/>}
          color={'white'}
          onClick={toggleAudio}
        >{myUser.audio ? L('calls.tooltip.mute') : L('calls.tooltip.unmute')}</Button>
      </TooltipWrap>
  }, [myUser, sm, createHint, L, toggleAudio, permissions.microphone]);

  const buttonVideo = useMemo(() => {
    if (!myUser) return null;
    return sm ? <TooltipWrap
      size={'big'}
      hint={myUser.video ? createHint(L('calls.video.stop_my_video'), ['⌘', 'V']) : createHint(L('calls.video.start_my_video'), ['⌘', 'V'])}
    >
      {!permissions.camera && <span className="media-permission">!</span>}
      <Button
        disabled={myUser?.isLoadingVideo}
        className={myUser.video ? 'rounded-corners-8 custom-btn-style bg-state' : 'rounded-corners-8 custom-btn-style bg-state red-icon'}
        isStroke
        size={'lg'}
        iconInside={myUser.video ? <Video/> : <VideoOff/>}
        color={'white'}
        onClick={toggleVideo}
      />
    </TooltipWrap> : <TooltipWrap
      size={'big'}
      hint={myUser.video ? createHint(L('calls.video.stop_my_video'), ['⌘', 'V']) : createHint(L('calls.video.start_my_video'), ['⌘', 'V'])}
    >
      {!permissions.camera && <span className="media-permission">!</span>}
      <Button
        disabled={myUser?.isLoadingVideo}
        className={myUser.video ? 'rounded-corners-8 custom-btn-style bg-state' : 'rounded-corners-8 custom-btn-style bg-state red-icon'}
        iconLeft={myUser.video ? <Video width={24} height={24}/> : <VideoOff width={24} height={24}/>}
        color={'white'}
        onClick={toggleVideo}
      >{myUser.video ? L('calls.video.stop') : L('calls.video.start')}</Button>
    </TooltipWrap>
  }, [myUser, sm, createHint, L, toggleVideo, permissions.camera]);

  const buttonShare = useMemo(() => {
    if (!myUser) return null;
    return sm ?
      <TooltipWrap
        size={'big'}
        hint={!answer ? L('calls.unable_to_share_screen') : myUser.isShare ? createHint(L('calls.tooltip.share.stop'), ['⌘', 'D']) : createHint(L('calls.share_content'), ['⌘', 'D'])}
      >
        <Button
          disabled={!isShareScreenPermitted  && !myUser?.isHost}
          className={'rounded-corners-8 custom-btn-style bg-state'}
          isStroke
          size={'lg'}
          iconInside={myUser.isShare ? <StopSharing/> : <ShareScreen/>}
          color={'white'}
          onClick={toggleShare}
        />
      </TooltipWrap> : <TooltipWrap
        className={'hide-button'}
        size={'big'}
        hint={!answer ? L('calls.unable_to_share_screen') : myUser.isShare ? createHint(L('calls.tooltip.share.stop'), ['⌘', 'D']) : createHint(L('calls.share_content'), ['⌘', 'D'])}
      >
        <Button
          disabled={!isShareScreenPermitted && !myUser?.isHost}
          className={'rounded-corners-8 custom-btn-style bg-state'}
          color={'white'}
          iconLeft={myUser.isShare ? <StopSharing width={24} height={24}/> :
            <ShareScreen width={24} height={24}/>}
          onClick={toggleShare}
        >{myUser.isShare ? L('calls.share.stop') : L('calls.share')}</Button>
      </TooltipWrap>
  }, [myUser, sm, answer, L, createHint, toggleShare, isShareScreenPermitted])

  /*  const buttonRecord = useMemo(() => {
    if (!myUser) return null;
    return <TooltipWrap
      className={'hide-button'}
      hint={!answer ? L('calls.unable_to_record') : L('calls.record')}
    >
      <Button
        disabled={!answer}
        size={'lg'}
        className={'rounded-corners-8 custom-btn-style bg-state'}
        iconInside={<Record/>}
        color={'white'}
        onClick={async () => {
          // TODO test room create
          // const r = await createRoom();
          // document.location.href = `http://localhost:3001?hash=${r.response.hash}&video=true`
        }}
        isStroke
      />
    </TooltipWrap>
  }, [answer, myUser])*/

  const buttonParticipants = useMemo(() => {
    if (!myUser || sm) return null;
    return <TooltipWrap
      hint={rightBarType === RIGHT_BAR_TYPES.PARTICIPANTS ? L('calls.hide_participants') : L('calls.show_participants')}>
      <div className={'person-add-button'}>
        <span className={'users-count'}>{users.length}</span>
        <Button
          hovered={rightBarType === RIGHT_BAR_TYPES.PARTICIPANTS}
          size={'lg'}
          className={'rounded-corners-8 bg-custom-l'}
          iconLeft={<PersonAdd width={24} height={24}/>}
          color={'white'}
          onClick={_openRightPanel}
          inverted
        >{`${L('calls.participants')} (${users.length})`}</Button>
      </div>
    </TooltipWrap>
  }, [myUser, sm, rightBarType, L, users.length, _openRightPanel])

  const buttonComments = useMemo(() => {
    if (sm) return null;
    else {
      return <TooltipWrap size={'big'} hint={'Show messages'}>
        <Button
          hovered={rightBarType === RIGHT_BAR_TYPES.COMMENTS}
          inverted
          size={'lg'}
          className={'rounded-corners-8 bg-custom-l comments-button'}
          iconLeft={
            <Layout gap='none' className='icon-with-counters'>
              <Chat width={24} height={24}/>
              {
              (chatSettings?.isShowCounter && commentsCounters?.count_unread > 0)
                ? <Counter
                  className={classNames({
                    'unread-rounded':true,
                  })}
                  size={16}
                  state={chatSettings?.isShowCounter ? "primary" : "dot-element"}
                  count={commentsCounters?.count_unread ?? 0}
                />
                : <></>
              }
            </Layout>
          }
          color={'white'}
          onClick={toggleCommentsPanel}
        >{L('calls.chat')}</Button>
      </TooltipWrap>;
    }
  }, [sm, rightBarType, chatSettings?.isShowCounter, commentsCounters?.count_unread, toggleCommentsPanel, L]);

  return (
    <div className={classNames(
      'action-bar',
      {
        'hide': !sm && (fullScreen ? isHide : isPinnedUI ? false : isHide),
        'static': isPinnedUI && !fullScreen && !sm,
        'is-small': sm,
        'is-mobile': isMobile()
      }
    )}>
      <div className={'is-small-action-bar'}>
        {buttonAudio}
        {buttonVideo}
        {!isMobile() && buttonShare}
        {myUser && <CallEndButton L={L} hint={createHint(L('calls.end_call'), ['⌘', 'L'])}/>}
      </div>
      <>
        <div className={`action-bar-left`}>
          {/*<Button
              className={`record-btn${recording.isRecord ? ' active' : ''}`}
              color={`black`}
              inverted={true}
              iconLeft={<Record width={24} height={24}/>}
              onClick={record}
            >{recording.text}</Button>*/}
        </div>
        <div className={`action-bar-center`}>
          <Layout gap={"sm"}>
            {buttonAudio}
            {buttonVideo}
            {!isMobile() && buttonShare}
            {myUser && <CallEndButton L={L} hint={createHint(L('calls.end_call'), ['⌘', 'L'])}/>}
          </Layout>
        </div>
        <div className={`action-bar-right`}>
          <Layout gap={"sm"}>
            {buttonParticipants}
            {buttonComments}
          </Layout>
        </div>
      </>
    </div>
  )
}

export default ActionBar;
